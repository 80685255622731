import React, { Fragment, useState, Suspense } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Stack,
  Drawer,
  Avatar,
} from "@mui/material";
import JLoadingPanel from "../src/components/Layout/JLoadingPanel";
import JNavLink from "../src/components/Layout/JNavLink";
import CompanyLogo from "./assets/company_logo.png";
import ContactUs from "./components/Pages/ContactUs";
import HomePage from "./components/Pages/HomePage";
import "./App.css";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <Fragment>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          minHeight: "100vh",
          backgroundSize: "100%",
          backgroundColor: "#ededed",
          backgroundRepeat: "no-repeat",
          display: "flex",
        }}
      >
        {/* <JLoadingPanel showLoadingPanel={state.isLoading} /> */}

        {/* Header */}
        <AppBar
          color="primary"
          sx={{
            backgroundColor: "#192841",
            display: "flex",
            maxWidth: "100%",
            // // { sm: `calc(100% - ${drawerWidth}px)` },
            // // ml: { sm: `${drawerWidth}px` },
          }}
          // md={{
          //   ml: { sm: `${drawerWidth}px` },
          // }}
        >
          <Toolbar
            variant="dense"
            style={{ height: 60, backgroundColor: "white" }}
          >
            <Avatar src={CompanyLogo}></Avatar>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 24,
                marginLeft: 2,
                color: "black",
                fontFamily: "Playfair Display",
              }}
            >
              Jinspire Solutions
            </Typography>
            <Box sx={{ flex: "1 1 auto" }} />
            <Stack
              spacing={2}
              direction="row"
              sx={{ display: { md: "flex", xs: "none" } }}
            >
              <JNavLink to={`home`}>Home</JNavLink>
              <JNavLink to={`contactus`}>Contact Us</JNavLink>
            </Stack>
            {/* 
          <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{ mr: { xs: 1 }, display: { md: 'none', xs: 'block' } }}
            >
              <MenuIcon />
            </IconButton>
 */}
          </Toolbar>
        </AppBar>

        <Box
          component="main"
          sx={{
            display: "static",
            height: "100%",
            minHeight: "9vh",
            width: "100%",
            // p: { xs: 1, md: 5 },
            pt: { xs: 9
             },
            // width: "100%" ,
            // md: `calc(100% - ${drawerWidth}px)`
          }}
        >
          {/* Body Content! */}
          <Box
            maxWidth="100%"
            sx={{
              height: "80%",
              minHeight: "87vh",
              display: "flex",
              p: 0,
            }}
          >
            <Box sx={{ flex: "1 1 auto" }} />
            <Box
              sx={{
       
                height: "80%",
                width: "1000px",
                display: "flex",
                p: 0,
              }}
            >
              <Suspense fallback={<p>Loading....</p>}>
                <Switch>
                  <Route path="/contactus">
                    <ContactUs />
                  </Route> 
                  <Route path="/privacypolicy">
                    <PrivacyPolicy />
                  </Route>
                  <Route path="*">
                    <HomePage />
                  </Route>
                </Switch>
              </Suspense>

              {/* Body Footer */}
            </Box>

            {/* <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                sx={{
                  // display: { sx: "block" },
                  "& .MuiDrawer-paper": {
                    backgroundColor: "#121828",
                    boxSizing: "border-box",
                    width: 280,
                    top: 50,
                  },
                }}
              >  

                  <Stack spacing={1} direction="column">
                  <JNavLink to={`homepage?be=${id}`}>Home</JNavLink>
                  {/* {tripTypes.map((tripType) => (
                    <JNavLink to={`${tripType.bookingEngineUrl}?be=${id}`}>{tripType.tripTypeName}</JNavLink>
                  ))} */}
            {/* <JNavLink to={`contactus?be=${id}`}>Contact Us</JNavLink>
                  </Stack>

              </Drawer> */}
            <Box sx={{ flex: "1 1 auto" }} />
          </Box>
          <Toolbar
            variant="dense"
            sx={{ backgroundColor: "black" }}
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Box sx={{ flex: "1 1 auto" }} />

            <Typography
              variant="overline"
              display="block"
              gutterBottom
              color="white"
            >
              Copyright @ Jinspire Solutions Pte. Ltd.
            </Typography>
          </Toolbar>
        </Box>
      </Box>
    </Fragment>
  );
}

export default App;
