import { Typography } from "@mui/material";
import JFormLayout from "../Layout/JFormLayout";
import JFormItem from "../Layout/JFormItem";
import JLabel from "../Layout/JLabel";
import { Fragment } from "react";

const PrivacyPolicy = () => {
 return <Fragment><JFormLayout>
    <JFormItem>
      <JLabel>Privacy Policy for VRP - Driver App</JLabel>
      <br /><br />
      <Typography>
        Effective Date: 01-June-2023 <br /><br />
        
        This Privacy Policy describes how VRP - Driver App ("we," "us," or "our") collects, uses, and safeguards the
        personal information of users ("you" or "your") when you use our mobile
        application VRP - Driver App ("the App"). By using the App, you consent
        to the terms of this Privacy Policy. <br /><br /> 

        1. Information Collection  <br /><br /> 
        
        1.1 Personal Information:<br />
         We may collect personal information that you voluntarily
        provide to us, such as your name, email address, and any other
        information you choose to provide when using the App, such as
        user-generated content or feedback. <br /><br /> 
        
        1.2 Device Information: <br /> We may
        collect certain information about your device, including the device
        type, operating system, unique device identifier, IP address, and mobile
        network information. This information helps us provide and improve the
        App's functionality, troubleshoot issues, and enhance your user
        experience. <br /><br /> 
        
        1.3 Usage Information: <br />We may collect information about how
        you use the App, including the features you access, the actions you
        take, and the duration and frequency of your usage. This information is
        used to analyze and improve the performance, functionality, and content
        of the App. <br /><br /> 
        
        1.4 Location Information: <br />With your consent, we may collect
        and process information about your precise location when you use
        location-based services within the App. This information is used to
        provide location-specific features or content and enhance your
        experience. <br /><br /> 
        
        2. Information Use 
        
        <br /><br /> 2.1 App Functionality: <br /> 
        
        We use the collected information to provide, maintain, and improve the functionality of the
        App, including personalized features, notifications, and updates. <br /><br /> 
        
        2.2 Communication: <br /> 
        We may use your contact information to send you important
        updates about the App, such as software updates, new features, or
        changes to this Privacy Policy. <br /><br /> 
        
        2.3 User Support: <br /> 
        We may use your information to respond to your inquiries, provide technical support, and
        address any issues or concerns you may have while using the App. <br /><br />
        
        2.4 Analytics and Research: <br /> 
        We may use the information for analytics purposes, such as analyzing user behavior and preferences, measuring the
        App's performance, and conducting research to improve our services and
        develop new features. <br /><br /> 
        
        3. Information Sharing <br /><br /> 
        
        3.1 Service Providers: <br />
        
        We may engage trusted third-party service providers to assist us in providing
        and improving the App. These service providers have access to your
        information only to perform specific tasks on our behalf and are
        obligated to handle it securely and in accordance with this Privacy
        Policy. <br /><br /> 
        
        3.2 Legal Compliance:  <br />
        We may disclose your information if required to do so by law, or if we believe that such disclosure is
        necessary to protect our rights, respond to legal requests, or ensure
        the safety of our users. <br /><br /> 
        
        3.3 Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your
        personal information may be transferred to the acquiring entity, who
        will assume the rights and obligations regarding your information as
        outlined in this Privacy Policy. <br /><br /> 
        
        4. Information Security <br /> 
        We implement reasonable security measures to protect your personal information
        against unauthorized access, alteration, disclosure, or destruction.
        However, please note that no method of transmission over the internet or
        electronic storage is 100% secure, and we cannot guarantee absolute
        security. <br /><br /> 
        
        5. Your Choices and Controls <br /> 
        You have the right to access, update, and delete your personal information stored within the App. You
        can exercise these rights by contacting us using the contact information
        provided at the end of this Privacy Policy. <br /><br /> 
        
        6. Children's Privacy <br /> 
        The App is not intended for use by individuals under the age of 13. If we become
        aware that we have collected personal information from a child under 13
        without parental consent, we will take steps to promptly delete the
        information. <br /><br /> 
        
        7. Changes to this Privacy Policy <br /> 
        
        We may update this Privacy Policy from time to time. The revised policy will be made available
        within the App, and we encourage you to review it periodically. Your
        continued use of the App after the effective date of the revised Privacy
        Policy constitutes your acceptance of the updated terms. <br /><br /> 
        
        8. Contact Us <br /> 
        If you have any questions, concerns, or requests regarding this Privacy
        Policy or our privacy practices, please contact us at
        admin@jinspiresolutions.com. Please note that this Privacy Policy is
        provided in English, and any translated versions are for convenience
        only. In the event of any discrepancies, the English version shall
        prevail.
      </Typography>
    </JFormItem>
  </JFormLayout></Fragment>
};

export default PrivacyPolicy;
