import { Fragment } from "react";
import JLabel from "../Layout/JLabel";
import { Grid } from "@mui/material";
import JFormLayout from "../Layout/JFormLayout";
import JFormItem from "../Layout/JFormItem";

const HomePage = () => {
    return <Fragment>
       
<JFormLayout>
    <JFormItem>
         <JLabel>Home Page</JLabel>
    </JFormItem>
    <JFormItem>
   
            <Grid container xs={12} columnSpacing={2} rowSpacing={8} flex>
                            <Grid item xs={12} md={6}>
                                <img src="https://jinspirestorage.blob.core.windows.net/bookingengine/carpool.jpg" 
                                alt="Carpool"
                                height="100%"
                                width="100%" />
                            </Grid>
                            <Grid item xs={12} md={6} rowSpacing={2} alignItems="center" rowGap={2}>
                                <JFormLayout>
                                    <JFormItem>
                                        <JLabel size="14" bold={true}>Vehicle Resource Planning</JLabel>
                                    </JFormItem> 
                                    <JFormItem>
                                        <JLabel size="12" bold={false}>A solution for transport service company to manage their trips reservation, passenger records, arrange schedule, 
                                        billing and collect payment records, and generate monthly report in one click. Besides that, Driver App for Driver to pickup and dropoff, claims submission, and Online Reservation function for passenger to book through online</JLabel>
                                    </JFormItem>    
                                    <JFormItem>
                                        <JLabel size="12" bold={false}>Suitable for Charter Services Company & Mover</JLabel>
                                    </JFormItem>                                                                 
                                </JFormLayout>                                                       
                            </Grid>
                        </Grid>

{/* 
                        <Grid container xs={12} columnSpacing={2} rowSpacing={2} flex>
                            <Grid item xs={12} md={6}>
                                <img src="https://jinspirestorage.blob.core.windows.net/bookingengine/carpool.jpg" 
                                alt="Carpool"
                                height="100%"
                                width="100%" />
                            </Grid>
                            <Grid item xs={12} md={6} rowSpacing={2} alignItems="center" rowGap={2}>
                                <JFormLayout>
                                    <JFormItem>
                                        <JLabel size="14" bold={true}>Booking Management System</JLabel>
                                    </JFormItem> 
                                    <JFormItem>
                                        <JLabel size="12" bold={false}>A solution for company to manage their reservation, customer records billing and collect payment records, and generate monthly report in one click. And also Online Reservation function for passenger to book through online</JLabel>
                                    </JFormItem>  
                                    <JFormItem>
                                        <JLabel size="12" bold={false}>Reservation oriented company such as Salon & Beauty</JLabel>
                                    </JFormItem>                                                                 
                                </JFormLayout>                                                       
                            </Grid>
                        </Grid>

                        <Grid container xs={12} columnSpacing={2} rowSpacing={2} flex>
                            <Grid item xs={12} md={6}>
                                <img src="https://jinspirestorage.blob.core.windows.net/bookingengine/carpool.jpg" 
                                alt="Carpool"
                                height="100%"
                                width="100%" />
                            </Grid>
                            <Grid item xs={12} md={6} rowSpacing={2} alignItems="center" rowGap={2}>
                                <JFormLayout>
                                    <JFormItem>
                                        <JLabel size="14" bold={true}>Order Taking System</JLabel>
                                    </JFormItem> 
                                    <JFormItem>
                                        <JLabel size="12" bold={false}>A solution for company to manage their sales order, , customer records billing and collect payment records, and generate monthly report in one click. And also Sales App for sales to enter order</JLabel>
                                    </JFormItem> 
                                    <JFormItem>
                                        <JLabel size="12" bold={false}>Suitable for Salesman, Restaurant</JLabel>
                                    </JFormItem>                                                                   
                                </JFormLayout>                                                       
                            </Grid>
                        </Grid> */}
    </JFormItem>
    <JFormItem>
                        <Grid container xs={12} columnSpacing={2} rowSpacing={2} flex>
                            <Grid item xs={12} md={6}>
                                <img src="https://jinspirestorage.blob.core.windows.net/jinspiresolutionswebsite/customized_solutions_img.jpg" 
                                alt="Customized-Solutions"
                                height="100%"
                                width="100%" />
                            </Grid>
                            <Grid item xs={12} md={6} rowSpacing={2} alignItems="center" rowGap={2}>
                                <JFormLayout>
                                    <JFormItem>
                                        <JLabel size="14" bold={true}>Customization Product and Solution Services</JLabel>
                                    </JFormItem> 
                                    <JFormItem>
                                        <JLabel size="12" bold={false}>A tailor made services to be provided and design a digital solution to fit your needs</JLabel>
                                    </JFormItem>                                                                 
                                </JFormLayout>                                                       
                            </Grid>
                        </Grid>
        
    </JFormItem>
</JFormLayout>
        
    </Fragment>
}

export default HomePage;