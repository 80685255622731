import { NavLink } from "react-router-dom";


const JNavLink = (props) => {

    return (<NavLink to={props.to} style={{
        textDecoration: "none",
        fontFamily: "Playfair Display",
        color: "black",
        fontWeight: 600,
        fontSize: 20,
        display: "flex",
        padding: "8px",
      }}
      activeStyle={{
        backgroundColor: "rgba(255, 255, 255, 0.08)",
        color: "#5048e5",
        borderRadius: "8px",
        fontWeight: 600,
        // padding: "6px 0px 6px 0px"
      }}
      >{props.children}</NavLink>)
}

export default JNavLink;